import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative font-montserrat" }
const _hoisted_2 = { class: "flex flex-row text-12px xxl:text-14px items-center w-full" }
const _hoisted_3 = { class: "font-semibold text-20px mr-8 whitespace-nowrap text-black-lp-200 capitalize" }
const _hoisted_4 = { class: "w-1/6 whitespace-nowrap" }
const _hoisted_5 = { class: "overflow-auto h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomDropdown = _resolveComponent("CustomDropdown")!
  const _component_Header = _resolveComponent("Header")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, null, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_CustomDropdown, {
              options: _ctx.statusData,
              placeholder: "Pilih status",
              selectedItem: _ctx.params.statusData,
              onSelect: _ctx.onSelectStatus
            }, null, 8, ["options", "selectedItem", "onSelect"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode("div", _hoisted_5, [
      _createVNode(_component_TableV2, {
        loading: _ctx.listData.loading,
        borderTop: false,
        borderBottom: false,
        columns: _ctx.columns,
        data: _ctx.listData.data,
        isEmpty: !_ctx.listData.data.length && !_ctx.params.statusData.value,
        emptyHeaderMessage: "Tidak Ada Denda Saldo Minus",
        emptyMessage: "Mulai buat Denda Saldo Minus dan cek daftarnya disini",
        class: "my-2",
        onClick: _ctx.goToDetail,
        pagination: _ctx.listData.pagination,
        "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.listData.pagination = $event)),
        onRequest: _ctx.fetchList,
        onTryAgain: _ctx.fetchList,
        paginationStyle: "v3",
        isDetailAble: ""
      }, null, 8, ["loading", "columns", "data", "isEmpty", "onClick", "pagination", "onRequest", "onTryAgain"])
    ])
  ]))
}