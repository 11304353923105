
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import statusListData from "@/app/infrastructures/misc/common-library/StatusListData";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import { BalanceMinusPenaltyController } from "@/app/ui/controllers/BalanceMinusPenaltyController";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
import { BalanceMinusPenaltyDetail } from "@/domain/entities/BalanceMinusPenalty";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { Vue } from "vue-class-component";

export default class BalanceMinusPenalty extends Vue {
  mounted() {
    this.fetchList();
  }

  get title(): string {
    return (this.$route as any)?.meta?.title;
  }

  params: ApiRequestList = new ApiRequestList({
    statusData: new OptionsClass({
      name: "Semua Status",
      value: "",
      code: "",
      key: "",
      id: 0,
      label: ""
    })
  });

  get statusData(): OptionsClass[] {
    return statusListData(["active", "inactive"]);
  }

  onSelectStatus(status: OptionsClass): void {
    this.params.statusData = status;
    this.listData.pagination.page = 1;
    this.fetchList();
  }

  listData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchList() {
    this.listData.loading = true;
    try {
      this.listData = await BalanceMinusPenaltyController.getList(
        new ApiRequestList({
          page: this.listData.pagination.page,
          limit: this.listData.pagination.limit,
          status: this.params.statusData.value
        })
      );
    } catch (err) {
      this.listData.message = parsingErrorResponse(err).type;
    } finally {
      this.listData.loading = false;
    }
  }

  columns = [
    {
      name: "ID",
      styleHead: "w-2/20 text-left whitespace-nowrap",
      render: (item: BalanceMinusPenaltyDetail) =>
        `<span class='text-left text-red-lp-200 flex justify-center'>${item.id}</span>`
    },
    {
      name: "Nama Denda",
      styleHead: "w-1/5 text-left whitespace-nowrap",
      render: (item: BalanceMinusPenaltyDetail) =>
        `<span class='text-left text-black-lp-300 flex justify-center'>${item.name}</span>`
    },
    {
      name: "Deskripsi",
      styleHead: "w-1/4 text-left",
      render: (item: BalanceMinusPenaltyDetail) =>
        `<span class='text-left text-black-lp-300 flex justify-center'>${item.description}</span>`
    },
    {
      name: "Berlaku untuk",
      styleHead: "w-1/5 text-left whitespace-nowrap",
      render: (item: BalanceMinusPenaltyDetail) =>
        `<span class='text-left text-black-lp-300 flex justify-center'>${item.appliedTo}</span>`
    },
    {
      name: "Tipe Partner",
      styleHead: "w-1/5 text-left whitespace-nowrap",
      render: (item: BalanceMinusPenaltyDetail) =>
        `<span class='text-left text-black-lp-300 flex justify-center'>${item.partnerType}</span>`
    },
    {
      name: "Status",
      styleHead: "w-2/20 text-left text-black-lp-300 whitespace-nowrap",
      render: (item: BalanceMinusPenaltyDetail) =>
        ChipsStyles({ status: item.status })
    }
  ];

  goToDetail(item: BalanceMinusPenaltyDetail) {
    this.$router.push(`/finance/balance-minus-penalty/${item.id}`);
  }
}
