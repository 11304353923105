
import { Options, Vue } from "vue-class-component";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import formatTimeCustom from "@/app/infrastructures/misc/common-library/FormatTimeCustom";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import formatDecimal from "@/app/infrastructures/misc/common-library/FormatDecimal";
import formatNumber from "@/app/infrastructures/misc/common-library/FormatNumber";
import formatInputDecimal from "@/app/infrastructures/misc/common-library/FormatInputDecimal";
import formatInputMoneyWithDecimal from "@/app/infrastructures/misc/common-library/FormatInputMoneyWithDecimal";
import {
  ErrorMessageEntities,
  OptionsClass,
  SubData
} from "@/domain/entities/MainApp";
import {
  BalanceMinusPenaltyDetail,
  BalanceMinusPenaltyDetailError,
  EMBARGO_COD_TYPE_LIST
} from "@/domain/entities/BalanceMinusPenalty";
import { CONFIRM_LIST_DATA } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { BalanceMinusPenaltyController } from "@/app/ui/controllers/BalanceMinusPenaltyController";
import { BALANCE_MINUS_PENALTY } from "@/app/infrastructures/misc/RolePermission";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { BalanceMinusPenaltyApiRequest } from "@/data/payload/api/BalanceMinusPenaltyApiRequest";

@Options({
  beforeRouteLeave(to: any, _: any, next: any) {
    if (this.isEdit) {
      if (MainAppController.messageModal || to.name === "login") {
        MainAppController.closeMessageModal();
        next();
        this.fetchDetail();
      } else {
        this.leavePageConfirmation = true;
        this.nextPath = to.path;
        next(false);
      }
    } else {
      next();
      this.fetchDetail();
    }
  }
})
export default class FormCourier extends Vue {
  leavePageConfirmation = false;
  nextPath = "";
  onLeavePage() {
    MainAppController.setOpenMessageModal(true);
    this.$router.push(this.nextPath);
  }

  get isEditable(): boolean {
    return checkRolePermission(BALANCE_MINUS_PENALTY.EDIT);
  }

  mounted() {
    this.fetchDetail();
  }

  get id(): number {
    return Number(this.$route.params?.id);
  }
  get isDetail(): boolean {
    return !!(this.$route.name as string)?.match(/detail/i);
  }
  get isEdit() {
    return !!(this.$route.name as string)?.match(/edit/i);
  }
  get title(): string {
    if (this.isDetail) {
      return this.detailData.name;
    }
    return `Ubah ${this.detailData.name}`;
  }
  get subData(): SubData {
    if (this.isDetail) {
      return new SubData({
        status: this.detailData.status,
        isEditable: this.isEditable,
        createdAt: this.detailData.createdAt,
        createdBy: this.detailData.createdBy,
        updatedAt: this.detailData.updatedAt,
        updatedBy: this.detailData.updatedBy,
        goEdit: this.goEdit
      });
    }
    return new SubData();
  }

  detailEntities = new ResponsePayloadV2();
  detailData = new BalanceMinusPenaltyDetail();

  async fetchDetail(): Promise<void> {
    try {
      this.detailEntities.loading = true;
      this.detailEntities = await BalanceMinusPenaltyController.getDetail(
        this.id
      );
      this.detailData = this.detailEntities.data;
    } catch (error) {
      const errorEntities: ErrorMessageEntities = parsingErrorResponse(error);
      this.detailEntities.message = errorEntities.type;
    } finally {
      this.detailEntities.loading = false;
    }
  }
  errors = new BalanceMinusPenaltyDetailError();
  get errorData(): any {
    return {
      errorIcon: false,
      errorCaption: "Min. harus lebih dari 0 dan Maks. 100",
      format: formatDecimal,
      suffix: "%"
    };
  }
  get isDisabled(): boolean {
    return (
      !this.detailData.description ||
      this.errors.percentagePenalty ||
      this.errors.percentageAfterPenalty ||
      this.errors.description ||
      this.errors.cutOffTime ||
      this.errors.maxMinusBalance ||
      this.errors.embargoCodType
    );
  }
  onChangePercentage(value: string, key: string) {
    this.errors[key] =
      !value ||
      !Number(formatNumber(value, { reset: true })) ||
      +formatInputDecimal(value) > 100;
  }
  onChangeValue(value: string, key: string) {
    this.errors[key] = !value || !value?.length;
  }
  formatDecimal(value: string) {
    return formatDecimal(value);
  }
  get cutOffTimeFormat(): string {
    return `${formatTimeCustom({
      date: this.detailData.cutOffTime.toString(),
      isSecond: false
    })} WIB`;
  }
  formatInputMoneyWithDecimal(value: any) {
    return formatInputMoneyWithDecimal(value);
  }
  get maxMinusBalanceFormat(): string {
    return String(
      formatNumber(this.detailData.maxMinusBalance, {
        suffix: "IDR"
      })
    );
  }
  get includeDayOffFormat(): string {
    return this.detailData.includeDayOff ? "Ya" : "Tidak";
  }
  get includeDayOffListData() {
    return CONFIRM_LIST_DATA;
  }
  get embargoCodTypeFormat(): string {
    return this.detailData.embargoCodType.replace(/,/gi, ", ");
  }
  get embargoCodTypeData(): OptionsClass[] {
    return EMBARGO_COD_TYPE_LIST;
  }
  onClickToggleStatus(value: boolean) {
    this.detailData.status = value ? "active" : "inactive";
  }

  goBack() {
    this.$router.push(
      this.isDetail
        ? "/finance/balance-minus-penalty"
        : `/finance/balance-minus-penalty/${this.id}`
    );
  }
  goEdit() {
    this.$router.push(`/finance/balance-minus-penalty/${this.id}/edit`);
  }
  onConfirm() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "badge-confirmation-general",
        title: `Simpan Perubahan “${this.detailData.name}”?`,
        message:
          "Pastikan kembali, detail denda saldo minus yang diperbarui sudah benar dan sesuai",
        textCancel: "Kembali",
        onClose: MainAppController.closeMessageModal,
        textSuccess: "Simpan",
        onSubmit: this.onEdit
      })
    );
  }
  async onEdit() {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      await BalanceMinusPenaltyController.edit(
        new BalanceMinusPenaltyApiRequest({
          payload: this.detailData
        })
      );
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          image: "badge-success",
          title: `“${this.detailData.name}” Berhasil Diperbarui`,
          message: "Perubahan detail denda saldo minus telah tersimpan",
          textSuccess: "OK",
          onSubmit: this.goBack,
          imageWidth: "32",
          imageHeight: "32"
        })
      );
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          error,
          "Gagal Mengubah Denda Saldo Minus!",
          this.onEdit
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }
}
